.footer-wrapper {
  padding: 0 10%;
  padding-bottom: calc(5% + 100px);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.footer img {
  width: 50px;
}

.footer-wrapper img {
  transition: transform 0.1s;
}

.footer-wrapper img:hover {
  transform: scale(1.15);
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.18));
}

@media screen and (max-width: 768px) {
  .footer-wrapper {
    padding: 5% 25%;
    padding-bottom: calc(15% + 100px);
  }

  .footer {
    flex-wrap: wrap;
  }
}
