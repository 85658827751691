@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  background-color: #64447a;
  line-height: 2.4em;
  /* color: #16111a; */
  color: #ffe1f2;
  font-family: "Lato", sans-serif;
  margin: 0;
}

.credits-container a {
  all: unset;
  cursor: pointer;
  padding: 3px 1px;
  text-decoration: underline;
  text-underline-offset: 0.25em;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0.5) 50%,
    transparent 50%
  );
  background-position-y: top;
  background-size: 200% 200%;
  transition: all 100ms ease-in;
}

h1,
h2,
h3 {
  font-family: degular-display, sans-serif;
  font-weight: 500;
}

a:hover {
  background-position-y: bottom;
}

.semibold {
  font-weight: 500;
}

.splash-wrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 200ms ease-out;
}

.App.transition .splash-wrapper {
  opacity: 0;
}

.App.final .splash-wrapper {
  display: none;
}

.splash-wrapper img {
  cursor: pointer;
  transition: height 200ms ease-out, width 200ms ease-out, transform 50ms;
}

.splash-wrapper img:active {
  transform: scale(0.975);
}

.App.transition .splash-wrapper img {
  height: 100vw;
  width: 100vw;
}

.content-wrapper {
  display: none;
  position: relative;
  padding: 5% 0;
  overflow-y: hidden;
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.App.transition .content-wrapper,
.App.final .content-wrapper {
  display: inherit;
  opacity: 1;
}

.background-texture {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -30%;
  background-image: url("images/bg-texture-2400w.png");
  background-size: contain;
  z-index: -10;
}

.orange-glow {
  position: absolute;
  left: 0;
  bottom: -50vw;
  height: 100vw;
  width: 100vw;
  border-radius: 50vw;
  background-color: #e38864;
  filter: blur(150px);
  z-index: -20;
}

.credits-container {
  display: flex;
  padding: 5% 10%;
  font-size: 1.6em;
  gap: 5%;
  opacity: 0;
  transition: all 200ms ease-in;
}

.App.final .credits-container {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .credits-container {
    flex-direction: column;
    padding: 5%;
  }
}

.credits-container > div {
  flex: 1;
}

.image-container {
  display: flex;
  justify-content: center;
}

.image-container img {
  height: 540px;
}

@media screen and (max-width: 768px) {
  .image-container img {
    height: 360px;
  }
}
