.player {
  height: 100px;
  width: 100px;
  background-color: #ffe1f2;
  position: fixed;
  bottom: 25px;
  right: 25px;
  border-radius: 75px;
  box-shadow: 2px 2px 15px 5px rgba(0, 161, 228, 0.5);
  transition: width 250ms ease-in-out, right 250ms ease-in-out,
    border-radius 50ms, transform 50ms;
  cursor: pointer;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  color: #16111a;
}

.player > * {
  grid-column: 1;
  grid-row: 1;
}

.player > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.player.expanded {
  width: 500px;
  border-radius: 50px;
  right: calc(50vw - 250px);
}

.player:hover {
  box-shadow: 2px 2px 15px 5px rgba(0, 161, 228, 1);
}

.player:active {
  transform: scale(0.975);
}

.player circle {
  transform: rotate(-90deg);
  transform-origin: 50px;
  stroke: rgba(22, 17, 26, 0.75);
  stroke-width: 4px;
  stroke-linecap: round;
  fill: transparent;
}
